import { useIsMobile } from "./MobileProvider";
import "./App.css";

const Email: React.FC = () => {
  const { isMobile } = useIsMobile();

  return (
    <div className={`text-center mt-5 mb-5`}>
      <span className={`${isMobile() ? "email-mobile" : "email"}`}>
        <a className="email-link" href="mailto:hello@phasein.studio">
          HELLO@PHASEIN.STUDIO
        </a>
      </span>
    </div>
  );
};

export default Email;
