import { useIsMobile } from "./MobileProvider";
import { MouseHighlight } from "./TextLayout";
import "./App.css";

const Title: React.FC = () => {
  const { isMobile } = useIsMobile();

  return (
    <div className={`${isMobile() ? "title-mobile" : "title"} text-center`}>
      <MouseHighlight>phasein</MouseHighlight>{" "}
      <MouseHighlight>studio</MouseHighlight>
    </div>
  );
};

export default Title;
