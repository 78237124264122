import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "./Footer";
import Title from "./Title";
import { MobileProvider } from "./MobileProvider";
import Email from "./Email";
import WorkExamples from "./WorkExamples";
import is6 from "./assets/images/games/infinite-silence/is6.png";
import "./Parallax.css";

function App() {
  return (
    <Router>
      <MobileProvider>
        <div className="App">
          <Container>
            <Row>
              <Col xl={10} lg={11} md={12} sm={12} className="mx-auto">
                <Title />

                <div className="text-center">
                  <span className="highlight-info">
                    PHASEIN STUDIO IS AN EXPERIMENTAL GAME STUDIO.
                  </span>
                </div>

                <Email />
              </Col>
            </Row>

            <Row>
              <Col xl={10} lg={11} md={12} sm={12} className="mx-auto"></Col>
            </Row>

            {/* Routing */}
            <Switch>
              <Route path="/*">
                <Redirect to="/" />
              </Route>
            </Switch>

            {/* <div className="spacer" /> */}
            {/* <div className="fade" />

          <div className="parallax-is-2" />
          <div className="parallax-is-11" />
          <div className="parallax-is-6" />
          <div className="parallax-ph-2" />
          <div className="parallax-ph-8" />
          <div className="parallax-ph-title" />
          <div className="parallax-ice" />
          <div className="parallax-oh-1" /> */}

            {/* <div className="parallax-vid" /> */}

            <Footer />
          </Container>
        </div>
      </MobileProvider>
    </Router>
  );
}

export default App;
