import { Link } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";
import { linkStyles, pageDescriptionStyles, emphStyles } from "./Globals";
import { useIsMobile } from "./MobileProvider";
import "./TextLayout.css";

type LinkProps = {
  url: string;
};

export const ExternalLink: React.FC<LinkProps> = ({ url, children }) => {
  return (
    <Link
      style={linkStyles}
      to={{
        pathname: url,
      }}
      target="_blank"
    >
      <div className="external-link">{children}</div>
    </Link>
  );
};

export const IntroText: React.FC = ({ children }) => {
  return (
    <Row className="px-2">
      <Col>
        <div className="px-4 py-4" style={pageDescriptionStyles}>
          {children}
        </div>
      </Col>
    </Row>
  );
};

export const Spacer: React.FC = () => {
  return <div className="spacer"></div>;
};

export const Emphasis: React.FC = ({ children }) => {
  return <span style={emphStyles}>{children}</span>;
};

export const MouseHighlight: React.FC = ({ children }) => {
  return <span className="mouse-highlight">{children}</span>;
};

export const MultiMouseHighlight: React.FC = ({ children }) => {
  const text = children?.valueOf().toString();
  return (
    <span>
      {text?.split(" ").map((word, index) => {
        return (
          <span key={word + index} className="mouse-highlight">
            {word}{" "}
          </span>
        );
      })}
    </span>
  );
};
