import { Row, Col, Container } from "react-bootstrap";

const Footer: React.FC = () => {
  const footerStyles = {
    fontSize: 18,
    fontWeight: 600,
  };

  return (
    <Container>
      <Row className="text-center py-5" style={footerStyles}>
        <Col>© 2022</Col>
      </Row>
    </Container>
  );
};

export default Footer;
